<template>
    <div class="container">
        <div class="title">{{title}}</div>
        <div v-html="content" class="content"></div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                slug: '',
                title: '',
                content: ''
            }
        },
        created() {
            let query =this.$route.query
            if(query) {
                this.title = query.title
                this.slug = query.slug
                this.getContent()
            }
        },
        computed: {
            curQuery() {
                return this.$route.query
            }
        },
        watch: {
            curQuery(val) {
                // console.log(val)
                this.title = val.title
                this.slug = val.slug
                this.getContent()
            }
        },
        methods: {
            getContent() {
                this.$api.setting({
                    slug: this.slug
                },false,600000).then(res => {
                    this.content = res.data || ''
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .container {
            width: 100%;
            
            .title {
                width: 100%;
                box-sizing: border-box;
                padding: 2rem 0;
                font-size: 2.4rem;
                font-weight: bold;
                color: #333333;
                text-align: center;
                word-wrap: break-word;
                border-bottom: 1px solid #e9e9e9;
            }
            
            .content {
                width: 100%;
                box-sizing: border-box;
                padding: 2rem 0;
            }
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .container {
            width: 100%;
            
            .title {
                width: 100%;
                box-sizing: border-box;
                padding: 2rem 0;
                font-size: 1.8rem;
                font-weight: bold;
                color: #333333;
                text-align: center;
                word-wrap: break-word;
                border-bottom: 1px solid #e9e9e9;
            }
            
            .content {
                width: 100%;
                box-sizing: border-box;
                padding: 2rem 0;
            }
        }
    }
</style>